import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DocumentCard,
  DocumentCardLogo,
  DocumentCardTitle,
  IDocumentCardTitleStyles,
  Stack,
  useTheme,
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { RfpResourceApi, RfpResponseDto } from "../../api";

const Rfps: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  899930SC-PCL0001 - Package Contents Listing (Rev 0b - Signed) (1).pdf */}
        <RfpsDocument
          title="899930SC-PCL0001"
          secondaryTitle="Package Contents Listing (Rev 0b - Signed)"
          id="5bb45256-e623-40fc-9408-3ed1d46d60e2"
        />

        {/*  RFP0001 Request for Proposals(Rev 0d) */}
        <RfpsDocument
          title="RFP0001"
          secondaryTitle="Request for Proposals(Rev 0d)"
          id="0ff5ced6-0400-46da-b3e3-054cb8998310"
        />

        {/*  Appendix A - Proposal Forms */}
        <RfpsFolder
          title="Appendix A"
          secondaryTitle="Proposal Forms (1 item)"
          onClick={() => navigate("appendix-a")}
        />
      </div>
      <div className="ms-Grid-row">
        {/*  Appendix B - Conditions of Contract */}
        <RfpsFolder
          title="Appendix B"
          secondaryTitle="Conditions of Contract (1 item)"
          onClick={() => navigate("appendix-b")}
        />

        {/*  Appendix C - Contract Forms */}
        <RfpsFolder
          title="Appendix C"
          secondaryTitle="Contract Forms (1 item)"
          onClick={() => navigate("appendix-c")}
        />

        {/*  Appendix D - Employer's Requirements */}
        <RfpsFolder
          title="Appendix D"
          secondaryTitle="Employer's Requirements (5 items)"
          onClick={() => navigate("appendix-d")}
        />
      </div>

      <div className="ms-Grid-row">
        {/*  Appendix E - Referenced Documents */}
        <RfpsFolder
          title="Appendix E"
          secondaryTitle="Referenced Documents (10 items)"
          onClick={() => navigate("appendix-e")}
        />

        {/*  Final Geotech Reports */}
        <RfpsFolder
          title="Final Geotech Reports"
          secondaryTitle="Final Geotech Reports (4 items)"
          onClick={() => navigate("final-geotech-reports")}
        />
      </div>
    </RfpsContainer>
  );
};

export const RfpsContainer: FunctionComponent<PropsWithChildren<{}>> = (
  props
) => {
  const theme = useTheme();

  return (
    <Stack
      horizontal
      horizontalAlign="center"
      styles={{ root: { padding: theme.spacing.l2 } }}
    >
      <Stack
        tokens={{ childrenGap: 40 }}
        className="ms-Grid container"
        dir="ltr"
        styles={{ root: { maxWidth: 1360 } }}
      >
        {props.children}
      </Stack>
    </Stack>
  );
};

interface RfpItemProp {
  title: string;
  secondaryTitle: string;
  onClick: VoidFunction;
}

export const RfpsFolder: FunctionComponent<RfpItemProp> = (props) => {
  const theme = useTheme();

  const cardTitlePrimaryStyles: IDocumentCardTitleStyles = {
    root: {
      paddingTop: theme.spacing.m,
      height: "auto",
    },
  };

  const cardTitleSecondaryStyles: IDocumentCardTitleStyles = {
    root: {
      paddingTop: 0,
    },
  };

  return (
    <div className="ms-Grid-col ms-sm4">
      <DocumentCard aria-label={props.title} onClick={props.onClick}>
        <DocumentCardLogo logoIcon="FolderOpen" />
        <DocumentCardTitle
          title={props.title}
          styles={cardTitlePrimaryStyles}
        />
        <DocumentCardTitle
          title={props.secondaryTitle}
          showAsSecondaryTitle
          styles={cardTitleSecondaryStyles}
        />
      </DocumentCard>
    </div>
  );
};

interface RfpDocumentProp {
  title: string;
  secondaryTitle: string;
  id?: string;
  onClick?: VoidFunction;
}

export const RfpsDocument: FunctionComponent<RfpDocumentProp> = (props) => {
  const [rfp, setRfp] = useState<RfpResponseDto>();
  const input = useRef<HTMLAnchorElement>(null);
  const theme = useTheme();

  const cardTitlePrimaryStyles: IDocumentCardTitleStyles = {
    root: {
      paddingTop: theme.spacing.m,
      height: "auto",
    },
  };

  useEffect(() => {
    (async () => {
      if (!props.id) return;

      const resource = new RfpResourceApi();
      const rfp = await resource.findRfp({ id: props.id });
      setRfp(rfp);
    })();
  }, [props.id]);

  const cardTitleSecondaryStyles: IDocumentCardTitleStyles = {
    root: {
      paddingTop: 0,
    },
  };

  return (
    <>
      <div className="ms-Grid-col ms-sm4">
        <DocumentCard
          aria-label={props.title}
          onClick={() => input.current?.click()}
        >
          <DocumentCardLogo logoIcon="Pdf" />
          <DocumentCardTitle
            title={props.title}
            styles={cardTitlePrimaryStyles}
          />
          <DocumentCardTitle
            title={props.secondaryTitle}
            showAsSecondaryTitle
            styles={cardTitleSecondaryStyles}
          />
        </DocumentCard>
      </div>
      {rfp && (
        <a
          aria-label={rfp.name}
          ref={input}
          href={`/rfp-documents/${rfp.name}`}
          download
          style={{ display: "none" }}
        >
          Download {rfp.name}
        </a>
      )}
    </>
  );
};

export default Rfps;
