import React, { FunctionComponent } from "react";
import { RfpsContainer, RfpsDocument } from "./Rfps";

const FinalGeotechReports: FunctionComponent = () => {
  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  GYPL-FU-GRRPT-60-0006_0.pdf */}
        <RfpsDocument
          title="GYPL-FU-GRRPT-60-0006_0.pdf"
          secondaryTitle="GYPL-FU-GRRPT-60-0006_0.pdf"
          id="ea43d9c1-aa3d-4243-91ba-d054c5f87ca2"
        />

        {/*  GYPL-FU-GRRPT-60-0003_0.pdf */}
        <RfpsDocument
          title="GYPL-FU-GRRPT-60-0003_0.pdf"
          secondaryTitle="GYPL-FU-GRRPT-60-0003_0.pdf"
          id="a411adf1-cad8-43b6-aedc-2ae2335784c4"
        />

        {/*  GYPL-FU-GRRPT-60-0002_B.pdf */}
        <RfpsDocument
          title="GYPL-FU-GRRPT-60-0002_B.pdf"
          secondaryTitle="GYPL-FU-GRRPT-60-0002_B.pdf"
          id="3da5d805-ed73-41c8-8b52-d65065c35945"
        />
      </div>

      <div className="ms-Grid-row">
        {/*  GYPL-FU-GRRPT-60-0001_0.pdf */}
        <RfpsDocument
          title="GYPL-FU-GRRPT-60-0001_0.pdf"
          secondaryTitle="GYPL-FU-GRRPT-60-0001_0.pdf"
          id="174d34d6-7fd4-4054-82aa-d1efbcab269d"
        />
      </div>
    </RfpsContainer>
  );
};

export default FinalGeotechReports;
