import React, { FunctionComponent } from "react";
import { RfpsContainer, RfpsDocument } from "../Rfps";

const GeneralTechnical: FunctionComponent = () => {
  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  899930ED-SPC0100 General Engineering Requirements (Rev 0a) */}
        <RfpsDocument
          title="899930ED-SPC0100"
          secondaryTitle="General Engineering Requirements"
          id="d4ef2374-54da-48f9-86da-8f8586120d2e"
        />

        {/*  899930EL-SPC0102-Electrical Functional Requirements(Rev 0a) */}
        <RfpsDocument
          title="899930EL-SPC0102"
          secondaryTitle="Electrical Functional Requirements(Rev 0a)"
          id="352eabc1-21d1-4b78-b747-42ee20bc8b11"
        />

        {/*  899930EN-SUM0106 - General Environmental Guidelines Summary (Rev 0a) */}
        <RfpsDocument
          title="899930EN-SUM0106"
          secondaryTitle="General Environmental Guidelines Summary (Rev 0a)"
          id="157364e8-3332-4472-a6b9-41c0476dac49"
        />
      </div>

      <div className="ms-Grid-row">
        {/*  899930IC-SPC0101-Instr and Controls Plant Functional Requirements (Rev 0a) */}
        <RfpsDocument
          title="899930IC-SPC0101"
          secondaryTitle="Instr and Controls Plant Functional Requirements (Rev 0a)"
          id="68d2df17-7e37-4a4d-beb2-61e12d98cd95"
        />

        {/*  899930ME-SPC0103-Pressure Vessel Functional Requirements(Rev 0a) */}
        <RfpsDocument
          title="899930ME-SPC0103"
          secondaryTitle="Pressure Vessel Functional Requirements(Rev 0a)"
          id="38a096f5-5d9f-4af4-a21a-6eec8c94518c"
        />

        {/*  899930ME-SPC0104-Heat Exchangers Functional Requirements(Rev 0a) */}
        <RfpsDocument
          title="899930ME-SPC0104"
          secondaryTitle="Heat Exchangers Functional Requirements(Rev 0a)"
          id="79dbfbda-647a-409d-8550-b48e4c2ce606"
        />
      </div>

      <div className="ms-Grid-row">
        {/*  899930ME-SPC0105-Storage Tanks Functional Requirements (Rev 0a) */}
        <RfpsDocument
          title="899930ME-SPC0105"
          secondaryTitle="Storage Tanks Functional Requirements (Rev 0a)"
          id="402c8c20-2e35-4c1b-ad48-af09fcae56ac"
        />

        {/*  3600089930ED-VDR0107 - Supplier Documentation Requirements Listing (Rev 0a)  */}
        <RfpsDocument
          title="3600089930ED-VDR0107"
          secondaryTitle="Supplier Documentation Requirements Listing (Rev 0a)"
          id="537e6789-44ee-4295-a867-2842b31b6731"
        />

        {/*  36000899930- Long Term Maintenance Program_(Rev 0a) */}
        <RfpsDocument
          title="36000899930"
          secondaryTitle="Long Term Maintenance Program_(Rev 0a)"
          id="5714bbbe-23d7-4a4d-a9e6-243120281acd"
        />
      </div>
    </RfpsContainer>
  );
};

export default GeneralTechnical;
