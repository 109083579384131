import React, { FunctionComponent } from "react";
import { RfpsContainer, RfpsDocument } from "./Rfps";

const AppendixE: FunctionComponent = () => {
  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  899930PA-PLT1005 - Site Location Plan */}
        <RfpsDocument
          title="899930PA-PLT1005"
          secondaryTitle="Site Location Plan"
          id="dcdba484-8355-447f-bb43-3e553536d517"
        />

        {/*  899930PA-RPT1002 - Flow Assurance Liquid Management Summary */}
        <RfpsDocument
          title="899930PA-RPT1002"
          secondaryTitle="Flow Assurance Liquid Management Summary"
          id="27e7afa2-a319-4054-a9b2-7076535c31b3"
        />

        {/*  899930PA-RPT1003 - Detailed Flood Study */}
        <RfpsDocument
          title="899930PA-RPT1003"
          secondaryTitle="Detailed Flood Study"
          id="e8fe0e8f-4cd5-40c7-b4d9-c1ea67b702ba"
        />
      </div>

      <div className="ms-Grid-row">
        {/*  899930PA-RPT1004 - Site Geotech Rpt - Prelim */}
        <RfpsDocument
          title="899930PA-RPT1004"
          secondaryTitle="Site Geotech Rpt - Prelim"
          id="2ecfd013-8024-4186-aa84-713563c295c3"
        />

        {/*  899930PA-RPT1006 - Lidar Report */}
        <RfpsDocument
          title="899930PA-RPT1006"
          secondaryTitle="Lidar Report"
          id="376ccb6a-2745-4250-867f-2baad2f9f14f"
        />

        {/*  899930PA-RPT1008 - 300 MW Gas Fired PP_Technoeconomic Study - Stage 1 */}
        <RfpsDocument
          title="899930PA-RPT1008"
          secondaryTitle="300 MW Gas Fired PP_Technoeconomic Study - Stage 1 "
          id="cef87c97-f273-4f02-9d6e-0e6e418e4480"
        />
      </div>

      <div className="ms-Grid-row">
        {/*  899930PA-RPT1009 - 300MW Gas Fired PP_Power System Study-Stage 2 */}
        <RfpsDocument
          title="899930PA-RPT1009"
          secondaryTitle="300MW Gas Fired PP_Power System Study-Stage 2"
          id="eecada05-5e42-4626-b507-c62598dad32e"
        />
        {/*  899930PA-SUM1007 - Enabling Works Summary */}
        <RfpsDocument
          title="899930PA-SUM1007"
          secondaryTitle="Enabling Works Summary"
          id="01fff4e3-5d62-41fa-83c9-b275abb6a617"
        />

        {/*  899930PA-TDA1000 - NGL Plant Pre-Feed Reference Package  */}
        <RfpsDocument
          title="899930PA-TDA1000"
          secondaryTitle="NGL Plant Pre-Feed Reference Package "
          id="649f6899-3817-43e6-993f-80b92c8e6e50"
        />
      </div>

      <div className="ms-Grid-row">
        {/*  RFP0006 App E - Reference Documents Listing(Rev 0b) */}
        <RfpsDocument
          title="RFP0006 App E"
          secondaryTitle="Reference Documents Listing(Rev 0b)"
          id="1f23e20e-a1fd-491e-8d68-01192a6ead98"
        />
      </div>
    </RfpsContainer>
  );
};

export default AppendixE;
