import React, { FunctionComponent } from "react";
import { RfpsContainer, RfpsDocument } from "./Rfps";

const AppendixA: FunctionComponent = () => {
  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  RFP0002 App A - Proposal Forms(Rev 0c).pdf */}
        <RfpsDocument
          title="RFP0002 App A"
          secondaryTitle="Proposal Forms(Rev 0c)"
          id="6c25dcd1-fd67-470d-b7a4-4026b5a6b263"
        />
      </div>
    </RfpsContainer>
  );
};

export default AppendixA;
