/* tslint:disable */
/* eslint-disable */
/**
 * gtep-rfp-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApplicantRequestDto,
    ApplicantRequestDtoFromJSON,
    ApplicantRequestDtoToJSON,
    ApplicantResponseDto,
    ApplicantResponseDtoFromJSON,
    ApplicantResponseDtoToJSON,
} from '../models';

export interface CreateApplicantRequest {
    applicantRequestDto?: ApplicantRequestDto;
}

export interface DeleteApplicantRequest {
    id: string;
}

export interface FindApplicantRequest {
    id: string;
}

export interface UpdateApplicantRequest {
    id: string;
    applicantRequestDto?: ApplicantRequestDto;
}

/**
 * 
 */
export class ApplicantResourceApi extends runtime.BaseAPI {

    /**
     */
    async createApplicantRaw(requestParameters: CreateApplicantRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/applicant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicantRequestDtoToJSON(requestParameters.applicantRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createApplicant(requestParameters: CreateApplicantRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.createApplicantRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteApplicantRaw(requestParameters: DeleteApplicantRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteApplicant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/applicant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteApplicant(requestParameters: DeleteApplicantRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteApplicantRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findApplicantRaw(requestParameters: FindApplicantRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ApplicantResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findApplicant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/applicant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicantResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async findApplicant(requestParameters: FindApplicantRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ApplicantResponseDto> {
        const response = await this.findApplicantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listApplicantsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ApplicantResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/applicant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApplicantResponseDtoFromJSON));
    }

    /**
     */
    async listApplicants(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ApplicantResponseDto>> {
        const response = await this.listApplicantsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateApplicantRaw(requestParameters: UpdateApplicantRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateApplicant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/applicant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicantRequestDtoToJSON(requestParameters.applicantRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateApplicant(requestParameters: UpdateApplicantRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.updateApplicantRaw(requestParameters, initOverrides);
    }

}
