import React, { FunctionComponent, useEffect, useState } from "react";
import { Stack, useTheme } from "@fluentui/react";
import { Outlet, Route, Routes, useParams } from "react-router-dom";

import Header from "./components/Header";
import AppendixA from "./components/rfps/AppendixA";
import AppendixB from "./components/rfps/AppendixB";
import AppendixC from "./components/rfps/AppendixC";
import AppendixD from "./components/rfps/AppendixD/AppendixD";
import BalanceOfPlant from "./components/rfps/AppendixD/BalanceOfPlant";
import GeneralTechnical from "./components/rfps/AppendixD/GeneralTechnical";
import NglPlant from "./components/rfps/AppendixD/NglPlant";
import PowerPlant from "./components/rfps/AppendixD/PowerPlant";
import AppendixE from "./components/rfps/AppendixE";
import Rfps from "./components/rfps/Rfps";
import { ApplicantResourceApi } from "./api";
import FinalGeotechReports from "./components/rfps/FinalGeotechReports";

const App: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <Stack
      verticalAlign="center"
      horizontal
      styles={{
        root: {
          height: "100vh",
          backgroundColor: theme.palette.whiteTranslucent40,
        },
      }}
    >
      <Routes>
        <Route path=":id" element={<Authenticate />}>
          <Route index element={<Rfps />} />
          <Route path="appendix-a" element={<AppendixA />} />
          <Route path="appendix-b" element={<AppendixB />} />
          <Route path="appendix-c" element={<AppendixC />} />

          <Route path="appendix-d">
            <Route index element={<AppendixD />} />
            <Route path="balance-of-plant" element={<BalanceOfPlant />} />
            <Route path="general-technical" element={<GeneralTechnical />} />
            <Route path="ngl-plant" element={<NglPlant />} />
            <Route path="power-plant" element={<PowerPlant />} />
          </Route>

          <Route path="appendix-e" element={<AppendixE />} />

          <Route
            path="final-geotech-reports"
            element={<FinalGeotechReports />}
          />
        </Route>
      </Routes>
    </Stack>
  );
};

const Authenticate: FunctionComponent = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      if (!id) return;

      const api = new ApplicantResourceApi();

      try {
        await api.findApplicant({ id });
        setAuthenticated(true);
      } catch (error) {}
    })();
  }, [id]);

  if (!authenticated) return null;

  return (
    <React.Fragment>
      <Header />
      <Outlet />
    </React.Fragment>
  );
};

export default App;
