import React, { FunctionComponent } from "react";
import { RfpsContainer, RfpsDocument } from "../Rfps";

const BalanceOfPlant: FunctionComponent = () => {
  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  899930ED-SPC0401-Balance of Plant Functional Requirements (Rev 0a) */}
        <RfpsDocument
          title="899930ED-SPC0401"
          secondaryTitle="Balance of Plant Functional Requirements (Rev 0a)"
          id="7668e20c-ca67-4c4f-8e5e-4b30036a02e6"
        />
      </div>
    </RfpsContainer>
  );
};

export default BalanceOfPlant;
