import React, { FunctionComponent } from "react";
import { RfpsContainer, RfpsDocument } from "../Rfps";

const NglPlant: FunctionComponent = () => {
  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  899930ED-SPC0201-NGL Plant Functional Requirements (Rev 0a) */}
        <RfpsDocument
          title="899930ED-SPC0201"
          secondaryTitle="NGL Plant Functional Requirements (Rev 0a)"
          id="bbefd28b-51b8-495d-9738-43dd17cd5001"
        />

        {/*  899930ME-SPC0203-Functional Requirements  for Compressors-Expanders in HC Service (Rev 0a) */}
        <RfpsDocument
          title="899930ME-SPC0203"
          secondaryTitle="Functional Requirements for Compressors-Expanders in HC Service (Rev 0a)"
          id="27480c9a-3361-4c2f-8707-9f9b1d58d8b3"
        />

        {/*  36000899930ME-SPC0202- Functional Requirements for Pumps - Hydrocarbon Service (Rev 0a) */}
        <RfpsDocument
          title="36000899930ME-SPC0202"
          secondaryTitle="Functional Requirements for Pumps - Hydrocarbon Service (Rev 0a)"
          id="faea2361-737e-470d-87d7-7a62c35dbc08"
        />
      </div>
    </RfpsContainer>
  );
};

export default NglPlant;
