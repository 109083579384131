import React, { FunctionComponent } from "react";
import { RfpsContainer, RfpsDocument } from "./Rfps";

const AppendixC: FunctionComponent = () => {
  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  RFP0004 App C - Contract Forms(Rev 0b) */}
        <RfpsDocument
          title="RFP0004 App C"
          secondaryTitle="Contract Forms(Rev 0b)"
          id="70a2baaa-f115-4a0f-91f6-8e2108ce9396"
        />
      </div>
    </RfpsContainer>
  );
};

export default AppendixC;
