import React, { FunctionComponent } from "react";
import { RfpsContainer, RfpsDocument } from "./Rfps";

const AppendixB: FunctionComponent = () => {
  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  RFP0003 App B - Conditions of Contract(Rev 0b) */}
        <RfpsDocument
          title="RFP0003 App B"
          secondaryTitle="Conditions of Contract(Rev 0b)"
          id="f6df0e66-47ec-4f37-9e2d-fa66c743a727"
        />
      </div>
    </RfpsContainer>
  );
};

export default AppendixB;
