/* tslint:disable */
/* eslint-disable */
/**
 * gtep-rfp-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicantResponseDto
 */
export interface ApplicantResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicantResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantResponseDto
     */
    mailingAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantResponseDto
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantResponseDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantResponseDto
     */
    updatedAt: string;
}

export function ApplicantResponseDtoFromJSON(json: any): ApplicantResponseDto {
    return ApplicantResponseDtoFromJSONTyped(json, false);
}

export function ApplicantResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicantResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'mailingAddress': json['mailingAddress'],
        'emailAddress': json['emailAddress'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function ApplicantResponseDtoToJSON(value?: ApplicantResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'mailingAddress': value.mailingAddress,
        'emailAddress': value.emailAddress,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}

