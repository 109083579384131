import React, { FunctionComponent } from "react";
import {
  FontWeights,
  IImageProps,
  Image,
  ImageFit,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import logo from "./logo.png";

const imageProps: IImageProps = {
  src: logo,
  imageFit: ImageFit.contain,
  width: 180,
};

const Header: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <Stack
      verticalFill
      styles={{
        root: {
          padding: theme.spacing.l2,
          backgroundColor: theme.palette.neutralLighterAlt,
        },
      }}
    >
      <Stack grow={2} />

      <Stack horizontal tokens={{ childrenGap: 15 }}>
        <Image alt="Ministry of Natural Resources" {...imageProps} />

        <Stack verticalAlign="center" tokens={{ childrenGap: 15 }}>
          <Stack>
            <Text
              variant="xLarge"
              styles={{ root: { fontWeight: FontWeights.semibold } }}
            >
              Petroleum Management Programme
            </Text>
            <Text variant="large">Gas to Energy Project</Text>
            <Text
              variant="medium"
              styles={{ root: { paddingTop: theme.spacing.s1 } }}
            >
              Request for Proposals Public Portal
            </Text>
          </Stack>
        </Stack>
      </Stack>

      <Stack grow={3} />
    </Stack>
  );
};

export default Header;
