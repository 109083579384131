import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { RfpsContainer, RfpsDocument, RfpsFolder } from "../Rfps";

const AppendixD: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  Balance of Plant */}
        <RfpsFolder
          title="Balance of Plant"
          secondaryTitle="(1 item)"
          onClick={() => navigate("balance-of-plant")}
        />

        {/*  General Technical */}
        <RfpsFolder
          title="General Technical"
          secondaryTitle="(9 item)"
          onClick={() => navigate("general-technical")}
        />

        {/*  NGL Plant */}
        <RfpsFolder
          title="NGL Plant"
          secondaryTitle="(3 item)"
          onClick={() => navigate("ngl-plant")}
        />
      </div>

      <div className="ms-Grid-row">
        {/*  Power Plant */}
        <RfpsFolder
          title="Power Plant"
          secondaryTitle="(5 item)"
          onClick={() => navigate("power-plant")}
        />

        {/*  RFP0005 App D - Employers Requirements Listing(Rev 0b) */}
        <RfpsDocument
          title="RFP0005 App D"
          secondaryTitle="Employers Requirements Listing(Rev 0b)"
          id="9762b1d9-cd94-4f3e-aa12-43cb6bb2f390"
        />
      </div>
    </RfpsContainer>
  );
};

export default AppendixD;
