import React, { FunctionComponent } from "react";
import { BrowserRouter } from "react-router-dom";
import { mergeStyles, ThemeProvider } from "@fluentui/react";

import App from "./App";
import theme from "./theme";

mergeStyles({
  ":global(html,body,#root)": {
    margin: 0,
    padding: 0,
  },
});

const Root: FunctionComponent = () => {
  return (
    <BrowserRouter basename="/public">
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Root;
