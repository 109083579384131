import React, { FunctionComponent } from "react";
import { RfpsContainer, RfpsDocument } from "../Rfps";

const PowerPlant: FunctionComponent = () => {
  return (
    <RfpsContainer>
      <div className="ms-Grid-row">
        {/*  89930EI-SLD0302 SHT 1-Power Plant Conceptual Electrical One Line Diagram(Rev 0) */}
        <RfpsDocument
          title="89930EI-SLD0302"
          secondaryTitle="Power Plant Conceptual Electrical One Line Diagram(Rev 0)"
          id="66358aab-a634-4084-b05f-61a38e1a5ef8"
        />

        {/*  89930EI-SLD0302 SHT 2-Power Plant Conceptual Electrical One Line Diagram(Rev 0) */}
        <RfpsDocument
          title="89930EI-SLD0302 SHT 2"
          secondaryTitle="Power Plant Conceptual Electrical One Line Diagram(Rev 0)"
          id="2f2e32f2-c25d-4b5b-b9b0-93279da9975f"
        />

        {/*  899930ED-SPC0301-Power Plant Functional Requirements(Rev 0a) */}
        <RfpsDocument
          title="899930ED-SPC0301"
          secondaryTitle="Power Plant Functional Requirements(Rev 0a)"
          id="43384fe3-b957-4f07-8918-7bf55116c2c1"
        />
      </div>

      <div className="ms-Grid-row">
        {/*  899930PA-DTS0316 - National Demand Profile(Rev 0) */}
        <RfpsDocument
          title="899930PA-DTS0316"
          secondaryTitle="National Demand Profile(Rev 0)"
          id="ad06cfd4-efde-45aa-be73-78edc2cb9510"
        />

        {/*  36000899930PA-MAN0311 - National Grid Code(Rev 0) */}
        <RfpsDocument
          title="36000899930PA-MAN0311"
          secondaryTitle="National Grid Code(Rev 0)"
          id="170a374e-29ce-4687-bd93-d191c9b050b9"
        />
      </div>
    </RfpsContainer>
  );
};

export default PowerPlant;
