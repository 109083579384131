/* tslint:disable */
/* eslint-disable */
/**
 * gtep-rfp-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RfpRequestDto,
    RfpRequestDtoFromJSON,
    RfpRequestDtoToJSON,
    RfpResponseDto,
    RfpResponseDtoFromJSON,
    RfpResponseDtoToJSON,
} from '../models';

export interface CreateRfpRequest {
    rfpRequestDto?: RfpRequestDto;
}

export interface FindRfpRequest {
    id: string;
}

/**
 * 
 */
export class RfpResourceApi extends runtime.BaseAPI {

    /**
     */
    async createRfpRaw(requestParameters: CreateRfpRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rfp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RfpRequestDtoToJSON(requestParameters.rfpRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createRfp(requestParameters: CreateRfpRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.createRfpRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findRfpRaw(requestParameters: FindRfpRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RfpResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findRfp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rfp/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RfpResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async findRfp(requestParameters: FindRfpRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RfpResponseDto> {
        const response = await this.findRfpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listRfpsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<RfpResponseDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rfp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RfpResponseDtoFromJSON));
    }

    /**
     */
    async listRfps(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<RfpResponseDto>> {
        const response = await this.listRfpsRaw(initOverrides);
        return await response.value();
    }

}
